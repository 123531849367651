<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="w-full">
    <svg
      v-if="block?.icon?.inlineSvg?.inner"
      v-bind="block?.icon?.inlineSvg?.attr"
      class="mx-auto mb-5 size-11"
      v-html="block?.icon?.inlineSvg?.inner"
    ></svg>
    <div
      v-if="block.redactorSimple"
      class="w-full break-words p-2 text-dynamic-primary [&_a]:text-primary-default [&_a]:underline [&_h1]:mb-4 [&_h1]:text-3xl [&_h1]:leading-normal md:[&_h1]:text-5xl [&_h2]:mb-4 [&_h2]:text-2xl [&_h2]:leading-normal md:[&_h2]:text-3xl [&_h3]:mb-3 [&_h3]:text-xl [&_h3]:leading-normal md:[&_h3]:text-xl [&_hr]:mt-5 [&_hr]:pb-5 [&_li]:relative [&_li]:py-1 [&_li]:pl-9 before:[&_li]:absolute before:[&_li]:left-0 before:[&_li]:top-[calc(5%+2px)] before:[&_li]:inline before:[&_li]:size-6 before:[&_li]:bg-[url('~/assets/icons/checkmark-dark.svg')] before:[&_li]:bg-center before:[&_li]:bg-no-repeat [&_ol:last-child]:pb-0 [&_ol]:pb-3 [&_p:last-child]:pb-0 [&_p]:pb-3 [&_p]:text-md [&_p]:leading-relaxed [&_ul:last-child]:pb-0 [&_ul]:pb-3"
      :class="`${block.backgroundColor} ${textAlign}`"
      v-html="block.redactorSimple"
    ></div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

type TextAlign = 'center' | 'left' | 'right';

const textAlignClasses: Record<TextAlign, string> = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

const textAlign = computed(
  () => textAlignClasses[props?.block?.textAlign as TextAlign] || ''
);
</script>
